import { useSetting } from 'hooks/useSetting';
import { CompanySetting } from 'types/Company';

export function useCompanySettings() {
  return useSetting<CompanySetting>(
    'company-settings',
    {
      defaultFormat: '',
      measurement_preferences: {
        area: '',
      },
    },
    [],
    undefined,
    undefined,
    true,
    true,
  );
}
